<style scoped>
.video-box{
    width: 300px;
    border-radius: 20px;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 2px 4px rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
  .video{
    width: 100%;
  }
  .video-css{
    width: 100%;
    height: 100%;
  }
</style>
<template>
	<div class="pl60 pr60 pt40 pb40" >
        <a-descriptions :bordered="true" title="公告信息" :column="{ xs: 1, sm: 1, md: 1}" v-if="info">
            <a-descriptions-item label="标题">{{info.title}}</a-descriptions-item>
            <a-descriptions-item label="内容">{{info.content}}</a-descriptions-item>

            <a-descriptions-item label="区域">
                <a-tag v-for="(t,i) in (info.province_code?province_code(info.province_code):[])" :key="i">{{t}}</a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="商品">{{info.goods_name || '-'}}</a-descriptions-item>
            <a-descriptions-item label="类型">{{info.type == 1 ? '图文' : '视频'}}</a-descriptions-item>

            <template v-if="info.type == 1 ">
                <a-descriptions-item label="图片">
                    <template v-if="!!info.imgs && info.imgs !== 'Array'">
                        <a-avatar 
                            v-for="(v,k) of info.imgs.split(',')" :key="k"
                            shape="square" 
                            :size="100" 
                            :src="IMGHOST + '/' + v" 
                        />
                    </template>
                    
                </a-descriptions-item>
            </template>
            <template v-else-if="info.type == 2">
                <a-descriptions-item label="视频">
                    <template v-if="info.video && videoOptions">
                        <div class="video-box">
                            <div class="video">
                                <my-video :options="videoOptions" class="video-css"/>
                            </div>
                        </div>
                    </template>
                </a-descriptions-item>
            </template>
        </a-descriptions>
	</div>
</template>
<script>
import myVideo from "@/components/myVideo.vue"
export default {
	components:{myVideo},
	data() {
		return {
            IMGHOST: window.IMGHOST,
            info: null,
            regionData: [],
            videoOptions: null
		}
	},
	computed: {
	},
	mounted() {
        this.$nextTick(()=>{
            this.info = this.$route.query
            if(this.info.type == 2) {
                this.videoOptions = {
                    width: 200, //视频播放器显示的宽度
                    height: 200, //视频播放器显示的高度
                    controls: true,//开启交互，即是用户可控。
                    muted: true,//开启视频时是否静音
                    fluid: true,//根据外层css样式大小，自动填充宽高！比较实用，可搭配响应式
                    reload: "auto",//重载
                    //其余设置根据需求添加！
                    poster: this.IMGHOST + '/' + this.info.cover_img,//视频封面
                    sources: [//视频播放源，建议本地
                        {
                            src: this.IMGHOST + '/' + this.info.video,
                            // type: "video/mp4"
                        }
                    ]
                }
            }
        })
        let data = this.$db.get('regionList').data;
        let regionData = [{
            code: 0,
            depth: 0,
            id: 0,
            name: '全部',
            pcode: 0
          }]
        for (let i = 0; i < data.length; i++) {
        if(data[i].depth==1){
            regionData.push(data[i])
        }
        }
        this.regionData = regionData

	},
	methods: {
        province_code(codes){
            codes = codes.split(',')
            let _this = this;
            let names = []
            for (let i = 0; i < codes.length; i++) {
                let result = _this.regionData.find((item)=>{return codes[i]==item.code})
                if(result){
                    names.push(result.name)
                }
            }
            return names
         },
	},
}
</script>
